.contact-form{
    padding: 56px 0 60px;
    .form-group {
    	.form-control {
    		border-radius: 0;
			padding: 20px;
			background: transparent;
			border-color: $primary-color;
		    min-height: 70px;
		    font-size: 16px;
		    width: 100%;
		    color: #afafaf;		
		    &:focus{
		    	outline: none;
		    	box-shadow: none;
		    	border-color: #afafaf;
		    	color: #fff;
		    }
    	}
    	textarea {
    		height:240px;
    	}
    }
}



/* Map Section */
.contact-map{
	position: relative;
	overflow: hidden;
	#map {
		height: 700px;
	}
	.contact-info{
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		z-index: 10;
		background: $brand-secondary;
		box-shadow: 0px 0 45px 8px #000;
	}
}


.content-title-section{
	padding-bottom: 38px;
}

.contact-content{
    padding: 28px 50px 0 90px;
}
.content-title,
.content-subtitle{
	font-family: $poppins;
	text-transform: uppercase;
	margin: 0;
}
.content-title{
	font-weight: 600;
    padding-bottom: 6px;
}
.contact-icon{
	display: inline-block;
	font-size: 30px;
	color: $primary-color;
	min-width: 56px;
	line-height: 1;
}

.ct-info{
	display: inline-block;
}

.home-address,
.web-address,
.phone-address{
    padding: 0 0 20px;
    p, a{
		margin: 0;
		padding: 5px 0;
		color: #afafaf;
    }
}

.contact-protfolio{
    padding-top: 20px;
	img{
		max-width: 72px;
		max-height: 72px;
		margin-right: 15px;
	}
}

.contact-form{
    padding: 55px 0;
    background: $service-bg;
}
