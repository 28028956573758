// Themefisher Variables file

/* Font Familys */
$poppins: 'Poppins', sans-serif;
$roboto: 'Roboto', sans-serif;
$playfair: 'Playfair Display', serif;
$lora: 'Lora', serif;


$font-fize: 14px;
$light: #fff;
$primary-color: #facd8a;

/* Themefisher Colors */

$primary-color: #facd8a;
$brand-secondary: #111111;
$white: #fff;
$gray: #323232;
$black: #0a0a0a;
$medium-dark: #0f0f0f;
/* Header bg */
$header-bg: #212121;
/*team overlay bg color */
$overlay-bg: #e7be82;
/* Service section bg */
$service-bg: #171717;

/* blog slider pagination img */
$blog-owl-buttons:#3c3c3c;


